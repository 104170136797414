module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-VM2L8YNCZS","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-WP6NXVG","cookieName":"gatsby-gdpr-google-tagmanager"},"facebookPixel":{"pixelId":"778593643427728","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"trackingId":"","cookieName":"gatsby-gdpr-linked-in"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef4434c372ba6a93a34fe6f3c25eb657"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Lato","file":"https://fonts.googleapis.com/css?family=Lato"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
